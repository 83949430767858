import React, { CSSProperties } from 'react';
import DOMPurify from 'dompurify';
import { CurrentSeverityValues, IncidentSubsystem, FilteredBrand } from '@typings/graph';
import { Datacenters } from '@constants';

export interface IIncidentLabelProps {
  styling: CSSProperties;
  text: string;
}
export function IncidentLabel({ text, styling }: IIncidentLabelProps): JSX.Element {
  return (
    <span css={{ ...styling }} id={text}>
      {text}
    </span>
  );
}

export function getIncidentIdForDisplay(jiraId): string {
  return jiraId.replace('INC-', 'Incident #');
}

export function convertUTCToLocalDate(date: string): string {
  const timeZoneValue = new Intl.DateTimeFormat().resolvedOptions();
  const dateLocal = new Date(date).toLocaleString(timeZoneValue.locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  });
  return dateLocal;
}
export function getLocalDateElement(date: string, css: CSSProperties): JSX.Element {
  const dateLocal = convertUTCToLocalDate(date);
  return <IncidentLabel styling={css} text={dateLocal} />;
}

export function getSanitizedIncidentNotesHtml(incidentNote: string): string {
  return DOMPurify.sanitize(unescape(incidentNote));
}

export function getLastUpdatedDateMinutes(date: string, css: CSSProperties): JSX.Element {
  // gets date in milliseconds and then pull minutes, hours and days. Append appropriate string to that calcualted time
  const dateDiff = Math.abs(new Date().getTime() - new Date(date).getTime());
  const minutes = Math.floor(dateDiff / 1000 / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days >= 1) {
    const displayString = days === 1 ? `${days} day ago` : `${days} days ago`;
    return <IncidentLabel styling={css} text={displayString} />;
  }
  if (hours < 24 && minutes > 60) {
    const displayString = hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    return <IncidentLabel styling={css} text={displayString} />;
  }
  const displayString = `${minutes} minutes ago`;
  return <IncidentLabel styling={css} text={displayString} />;
}

export function getIncidentSeverity(incidentSubsystems: IncidentSubsystem[]): CurrentSeverityValues {
  const incidentSeverity = incidentSubsystems.find(
    s => s.severity !== null && s.severity !== CurrentSeverityValues.Operational
  ).severity;
  return incidentSeverity || CurrentSeverityValues.Operational;
}
export function getIncidentDatacenters(uniqueIncidentBrands: FilteredBrand[]): string {
  const uniqueDatacentersCount = Array.from(new Set(uniqueIncidentBrands.map(item => item.datacenterId)));
  uniqueIncidentBrands.map(b => b.datacenterId);
  if (uniqueDatacentersCount.length === 2) {
    return 'North America Datacenter;Europe Datacenter';
  }
  if (uniqueDatacentersCount.length === 1) {
    return Object.keys(Datacenters)[Object.values(Datacenters).indexOf(uniqueDatacentersCount[0])];
  }
  return '';
}

import React from 'react';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import LinkList from '@cvent/carina/components/LinkList';
import FormElement from '@cvent/carina/components/FormElement/FormElement';
import { CurrentSeverityValues, IncidentSubsystem, Product } from '@typings/graph';
import { incidentPageDCID } from '@dataCventIds';
import { getStatusName, StatusIcons } from '@components/page/Home/Products/incidentStatusIcons';
import { Popover } from '@cvent/carina/components/Popover';
import { convertUTCToLocalDate } from '@components/page/incident/common';

interface ISubsystemStatusProps {
  datacenterSubsystemId: number;
  incidentEntities: IncidentSubsystem[];
  incidentSeverity: CurrentSeverityValues;
}

// To Do: convert popover to use popper after transitioning to carina v1

function SubsystemStatus({
  datacenterSubsystemId,
  incidentEntities,
  incidentSeverity
}: ISubsystemStatusProps): JSX.Element {
  const incidentEntity = incidentEntities.find(ie => ie.datacenterSubsystemId === datacenterSubsystemId);
  if (incidentEntity) {
    return (
      <Popover
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={({ toggleOpen, closePopover }) => (
          <span onMouseEnter={toggleOpen} style={{ cursor: 'pointer' }} onMouseLeave={closePopover}>
            <StatusIcons
              severity={incidentEntity.endDate ? CurrentSeverityValues.Operational : incidentEntity.severity}
            />
          </span>
        )}
        portal
        zIndex={20}
      >
        <>
          <Row margin={{ start: 0, end: 0 }}>
            <Col width={1 / 2} padding={{ start: 0, end: 0 }} css={{ fontWeight: 400 }}>
              Incident Type
            </Col>
            <Col width={1 / 2} padding={{ end: 0, start: 0 }}>
              <FormElement.Label label={getStatusName(incidentSeverity)} labelFor="Incident Type" />
            </Col>
          </Row>
          <Row margin={{ top: 18 }}>
            <Col width={1 / 2} padding={{ start: 0, end: 0 }} css={{ fontWeight: 400 }}>
              Began
            </Col>
            <Col width={1 / 2} padding={{ start: 0, end: 0 }}>
              <FormElement.Label label={convertUTCToLocalDate(incidentEntity.startDate)} labelFor="Impact Start Date" />
            </Col>
          </Row>
          {incidentEntity.endDate && (
            <Row margin={{ top: 18 }}>
              <Col width={1 / 2} padding={{ start: 0, end: 0 }} css={{ fontWeight: 400 }}>
                Incident Resolved
              </Col>
              <Col width={1 / 2} padding={{ start: 0, end: 0 }}>
                <FormElement.Label label={convertUTCToLocalDate(incidentEntity.endDate)} labelFor="Incident Resolved" />
              </Col>
            </Row>
          )}
        </>
      </Popover>
    );
  }
  return null;
}

export interface IIncidentEntityProps {
  incidentProducts: Product[];
  incidentEntities: IncidentSubsystem[];
  incidentSeverity: CurrentSeverityValues;
}

export function IncidentEntities({
  incidentProducts,
  incidentEntities,
  incidentSeverity
}: IIncidentEntityProps): JSX.Element {
  if (incidentProducts) {
    return (
      <Row margin={{ top: -15, start: 0 }}>
        {incidentProducts.map(product => (
          <Col l={{ width: 1 }}>
            <Row key={product.id}>
              <LinkList>
                <LinkList.Item
                  // @ts-expect-error FIXME: carina typing
                  text={<span style={{ fontWeight: 500, fontStyle: 'Medium' }}>{product.name}</span>}
                  isInteractive={false}
                  _testID={`${incidentPageDCID.productList}-${product.name}`}
                />
              </LinkList>
            </Row>
            <Row margin={{ end: -10, top: -10 }}>
              <Col width="fill" padding={{ end: 0, start: 0 }}>
                {product.subsystems.map(subsystem => (
                  <Row css={{ fontSize: '14px' }} key={subsystem.id}>
                    <Col width={3 / 4}>
                      <FormElement.Label
                        labelFor={`${incidentPageDCID.subsystemList}-${subsystem.id}-col`}
                        label={subsystem.name}
                        testID={`${incidentPageDCID.subsystemList}-${subsystem.id}`}
                      />
                    </Col>
                    <Col
                      width={1 / 4}
                      flex={{ display: 'flex', justifyContent: 'right' }}
                      padding={{ end: 0 }}
                      id={`${incidentPageDCID.subsystemList}-${subsystem.id}-col`}
                    >
                      <SubsystemStatus
                        datacenterSubsystemId={subsystem.datacenterSubsystemId}
                        incidentEntities={incidentEntities}
                        incidentSeverity={incidentSeverity}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
  return <FormElement.Label labelFor="No Products and subsystems" label="No Products and subsystems" />;
}

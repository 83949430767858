import React, { useRef } from 'react';
import { Form } from '@cvent/carina/components/Forms/Form';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import FormElement from '@cvent/carina/components/FormElement/FormElement';
import {
  FilteredBrand,
  GetIncidentBrandsProductsSubsystemsQueryVariables,
  Incident,
  useGetIncidentBrandsProductsSubsystemsQuery
} from '@typings/graph';
import { incidentPageDCID } from '@dataCventIds';
import useStyle from '@hooks/useThemeHelper';
import { PanelProducts } from '@components/page/incident/incidentPanel/incidentProducts';
import { PanelNotes } from '@components/page/incident/incidentPanel/incidentNotes';
import { getStatusName } from '@components/page/Home/Products/incidentStatusIcons';
import { LoadingPage } from '@components/LoadingPage';
import { POSTGRES_DEFAULT_INCIDENT_END_DATE } from '@constants';
import {
  getIncidentDatacenters,
  getIncidentSeverity,
  getLocalDateElement,
  IncidentLabel,
  getIncidentIdForDisplay
} from '../common';
import { usePageStyles } from '../../../styles';

export interface IIncidentPanelProps {
  incidentObj: Incident;
  incidentStatus?: string;
}

export function IncidentPagePanel({ incidentObj, incidentStatus = '' }: IIncidentPanelProps): JSX.Element {
  const { incidentLabels, incidentValueLabels, incidentInformationPanelStyle } = useStyle(usePageStyles);
  const incidentBrands = useRef<FilteredBrand[]>();
  const incidentDatacenters = useRef<string>();
  const incidentDatacentersubsystemIds = incidentObj.incidentSubsystems.map(
    incidentSubsystem => incidentSubsystem.datacenterSubsystemId
  );
  const getIncidentBrandsProductSubsystemsVariables: GetIncidentBrandsProductsSubsystemsQueryVariables = {
    datacenterSubsystemIds: incidentDatacentersubsystemIds
  };
  const { data, loading } = useGetIncidentBrandsProductsSubsystemsQuery({
    variables: getIncidentBrandsProductSubsystemsVariables
  });
  if (loading) {
    return <LoadingPage />;
  }

  if (data?.getIncidentBrandsProductsSubsystems?.filteredBrands) {
    incidentBrands.current = data?.getIncidentBrandsProductsSubsystems?.filteredBrands;
    const uniqueBrands = Array.from(new Map(incidentBrands.current.map(item => [item.id, item])).values());
    incidentDatacenters.current = getIncidentDatacenters(incidentBrands.current);
    const incidentSeverity = getIncidentSeverity(incidentObj.incidentSubsystems);

    return (
      <Form element="div">
        <div css={incidentInformationPanelStyle}>
          <h3>{incidentObj.name}</h3>
          <Row margin={{ top: 29 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <FormElement.Label
                labelFor="incidentId"
                label={<IncidentLabel styling={incidentLabels} text="Incident Id" />}
                testID={incidentPageDCID.incidentIdLabel}
              />
            </Col>
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
              <FormElement.Label
                labelFor={incidentObj.jiraId}
                label={
                  <IncidentLabel styling={incidentValueLabels} text={getIncidentIdForDisplay(incidentObj.jiraId)} />
                }
                testID={incidentPageDCID.incidentIdValue}
              />
            </Col>
          </Row>
          <Row margin={{ top: 20 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <FormElement.Label
                labelFor="incidentTitle"
                label={<IncidentLabel styling={incidentLabels} text="Title" />}
                testID={incidentPageDCID.incidentTitleLabel}
              />
            </Col>
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
              <FormElement.Label
                labelFor={incidentObj.name}
                label={<IncidentLabel styling={incidentValueLabels} text={incidentObj.name} />}
                testID={incidentPageDCID.incidentTitle}
              />
            </Col>
          </Row>
          <Row margin={{ top: 20 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <FormElement.Label
                labelFor="incidentStatus"
                label={<IncidentLabel styling={incidentLabels} text="Incident Status" />}
                testID={incidentPageDCID.statusLabel}
              />
            </Col>
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }} testID={incidentPageDCID.statusValue}>
              <FormElement.Label
                labelFor={incidentStatus}
                label={<IncidentLabel styling={incidentValueLabels} text={incidentStatus} />}
              />
            </Col>
          </Row>
          <Row margin={{ top: 20 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <FormElement.Label
                labelFor="type"
                label={<IncidentLabel styling={incidentLabels} text="Incident Type" />}
                testID={incidentPageDCID.impactCategoryLabel}
              />
            </Col>
            {/* to do: fix it */}
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
              <FormElement.Label
                labelFor={incidentSeverity}
                label={<IncidentLabel styling={incidentValueLabels} text={getStatusName(incidentSeverity)} />}
                testID={incidentPageDCID.impactCategoryValue}
              />
            </Col>
          </Row>
          <Row margin={{ top: 20 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <div style={{ fontWeight: 'bold' }}>
                <FormElement.Label
                  labelFor="datacenters"
                  label={<IncidentLabel styling={incidentLabels} text="Datacenter(s)" />}
                  testID={incidentPageDCID.datacenterLabel}
                />
              </div>
            </Col>
            {/* ///might need to massage into incidentRegions by deriving datacenters */}
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
              <FormElement.Label
                labelFor={incidentDatacenters.current}
                // to do: fix it
                label={<IncidentLabel styling={incidentValueLabels} text={incidentDatacenters.current} />}
                testID={incidentPageDCID.datacenterValue}
              />
            </Col>
          </Row>
          <Row margin={{ top: 20 }}>
            <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
              <FormElement>
                <FormElement.Label
                  labelFor="incidentstartDate"
                  label={<IncidentLabel styling={incidentLabels} text="Incident Began" />}
                  testID={incidentPageDCID.startDateLabel}
                />
              </FormElement>
            </Col>
            <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
              <FormElement.Label
                labelFor={incidentObj.startDate}
                label={getLocalDateElement(incidentObj.startDate, incidentValueLabels)}
                testID={incidentPageDCID.startDateValue}
              />
            </Col>
          </Row>
          {incidentObj.endDate && incidentObj.endDate !== POSTGRES_DEFAULT_INCIDENT_END_DATE && (
            <Row margin={{ top: 20 }}>
              <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} padding={{ left: -5 }}>
                <FormElement.Label
                  labelFor="incidentEndDate"
                  label={<IncidentLabel styling={incidentLabels} text="Incident Resolved" />}
                  testID={incidentPageDCID.endDateLabel}
                />
                {/* <b>Incident End Date:</b> */}
              </Col>
              <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
                <FormElement.Label
                  labelFor={incidentObj.endDate}
                  label={getLocalDateElement(incidentObj.endDate, incidentValueLabels)}
                  testID={incidentPageDCID.endDateValue}
                />
              </Col>
            </Row>
          )}
        </div>
        <PanelProducts
          incident={incidentObj}
          incidentBrandsProducts={uniqueBrands?.length > 0 ? uniqueBrands : []}
          incidentSeverity={incidentSeverity}
        />
        <PanelNotes
          currentUpdate={incidentObj.currentUpdate}
          lastModifiedDate={incidentObj.lastModified}
          customerImpact={incidentObj.customerImpact}
          incidentId={incidentObj.id}
        />
      </Form>
    );
  }
  return null;
}

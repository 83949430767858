import { CurrentSeverityValues, FilteredBrand, Incident } from '@typings/graph';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import { incidentPageDCID } from '@dataCventIds';
import React from 'react';
import { Item, LinkList } from '@cvent/carina/components/LinkList';
import { CommonStatusLabel } from '@components/CommonStatusLabelComponent';
import { TextLink } from '@cvent/carina/components/TextLink';

export interface IPanelProductsProps {
  incident: Incident;
  incidentBrandsProducts: FilteredBrand[];
  incidentSeverity: CurrentSeverityValues;
}

export function PanelProducts({
  incident,
  incidentBrandsProducts,
  incidentSeverity
}: IPanelProductsProps): JSX.Element {
  if (incidentBrandsProducts) {
    return (
      <Row css={{ width: '100%' }}>
        <Row>
          <Col flex={{ display: 'flex', justifyContent: 'left' }} width={3 / 4} padding={{ start: 0 }}>
            <h3 style={{ paddingLeft: '24px' }}>Products Impacted</h3>
          </Col>
          <Col
            flex={{ display: 'flex', justifyContent: 'right' }}
            width="fill"
            css={{ marginTop: 'auto', marginBottom: 'auto' }}
            padding={{ start: 0, end: 30 }}
          >
            <TextLink text="View Details" href={`/incident/${incident.id}`} />
          </Col>
        </Row>
        <Row margin={{ start: 12 }}>
          <Col width="fill">
            {incidentBrandsProducts.map(brand => {
              const uniqueProducts = Array.from(new Map(brand.products.map(p => [p.id, p])).values());
              return (
                <LinkList testID={incidentPageDCID.productList}>
                  <Item
                    // @ts-expect-error FIXME: carina typing
                    _testID={`${incidentPageDCID.brandList}-${brand.name}`}
                  >
                    <span style={{ fontSize: '16px', fontWeight: 500, fill: 'solid #1A2026' }}>{brand.name}</span>
                  </Item>
                  <>
                    {uniqueProducts.map(product => (
                      <Row margin={{ top: 10 }} testID={`${product.name}-${product.id}`} key={product.id}>
                        <Col width={3 / 4} key={`${product.id}-col`}>
                          {product.name}
                        </Col>
                        <Col width="fill">
                          <CommonStatusLabel
                            incidentSeverity={incidentSeverity}
                            impactedSubsystemsCount={product.subsystems.length}
                          />
                        </Col>
                      </Row>
                    ))}
                  </>
                </LinkList>
              );
            })}
          </Col>
        </Row>
      </Row>
    );
  }
  return null;
}

import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import { incidentPageDCID } from '@dataCventIds';
import React from 'react';
import useStyle from '@hooks/useThemeHelper';
import { usePageStyles } from '@components/styles';
import { getLastUpdatedDateMinutes, getSanitizedIncidentNotesHtml } from '@components/page/incident/common';
import FormElement from '@cvent/carina/components/FormElement/FormElement';
import { TextLink } from '@cvent/carina/components/TextLink';

export interface IPanelNotesProps {
  currentUpdate: string;
  lastModifiedDate: string;
  customerImpact: string;
  incidentId: string;
}

export function PanelNotes({
  currentUpdate,
  lastModifiedDate,
  customerImpact,
  incidentId
}: IPanelNotesProps): JSX.Element {
  const { incidentNotes, incidentValueLabels, incidentNotesSubHeaders } = useStyle(usePageStyles);
  return (
    <Row css={{ width: '100%' }} margin={{ bottom: 45 }}>
      <h3 style={{ paddingLeft: '24px' }}>Incident Notes</h3>
      <Row margin={{ start: 12 }}>
        <Col width="fill" padding={{ start: 12 }}>
          <div style={incidentNotesSubHeaders}>Customer Impact</div>
          <p
            css={{ ...incidentNotes }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getSanitizedIncidentNotesHtml(customerImpact)
            }}
          />
        </Col>
      </Row>
      {currentUpdate && (
        <Row margin={{ start: 12 }}>
          <Col width={1} padding={{ start: 12 }}>
            <Row margin={{ start: 0 }}>
              <Col width={2.5 / 4} padding={{ start: 0 }}>
                <div style={incidentNotesSubHeaders}>Latest Update</div>
              </Col>
              <Col width={1.5 / 4} flex={{ display: 'flex', justifyContent: 'right' }}>
                <FormElement.Label
                  labelFor={lastModifiedDate}
                  label={getLastUpdatedDateMinutes(lastModifiedDate, incidentValueLabels)}
                  testID={incidentPageDCID.lastModifiedDateValue}
                />
              </Col>
            </Row>
            <p
              css={{ ...incidentNotes }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: getSanitizedIncidentNotesHtml(currentUpdate)
              }}
            />
          </Col>
        </Row>
      )}
      <Row margin={{ start: 12 }}>
        <Col width={1} padding={{ start: 12 }}>
          <TextLink href={`/incident/${incidentId}`}>View Incident History</TextLink>
        </Col>
      </Row>
    </Row>
  );
}

import React, { useRef } from 'react';
import { Form } from '@cvent/carina/components/Forms/Form';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import type { Icon } from '@cvent/carina/components/Icon/types';
import FormElement from '@cvent/carina/components/FormElement/FormElement';
import Accordion from '@cvent/carina/components/Accordion/Accordion';
import LinkList from '@cvent/carina/components/LinkList';
import { IncidentEntities } from '@components/page/incident/incidentPage/incidentEntities';
import { IncidentNotes } from '@components/page/incident/incidentPage/incidentNotes';
import {
  FilteredBrand,
  GetIncidentBrandsProductsSubsystemsQueryVariables,
  Incident,
  useGetIncidentBrandsProductsSubsystemsQuery
} from '@typings/graph';
import { incidentPageDCID } from '@dataCventIds';
import { CommonContentContainer } from '@components/CommonContentContainer';
import { usePageStyles } from '@components/styles';
import useStyle from '@hooks/useThemeHelper';
import { getStatusName } from '@components/page/Home/Products/incidentStatusIcons';
import { LoadingPage } from '@components/LoadingPage';
import { POSTGRES_DEFAULT_INCIDENT_END_DATE } from '@constants';
import {
  getIncidentDatacenters,
  getIncidentSeverity,
  getLocalDateElement,
  IncidentLabel,
  getIncidentIdForDisplay
} from '../common';

type Section = {
  icon?: Icon;
  expandedByDefault?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
  content?: React.ReactNode;
};

function getUniqueBrands(brands: FilteredBrand[]): FilteredBrand[] {
  return brands.reduce((acc, brand) => {
    if (!acc.some(b => b.id === brand.id)) {
      const newBrand = { ...brand };
      newBrand.products = newBrand.products.filter(product => product.subsystems && product.subsystems.length > 0);
      acc.push(newBrand);
    }
    return acc;
  }, []);
}

function getAccordionSections(brandDisplayName, productEntities, incidentEntities, incidentSeverity): Section[] {
  const content = (
    <IncidentEntities
      incidentProducts={productEntities}
      incidentEntities={incidentEntities}
      incidentSeverity={incidentSeverity}
    />
  );
  const sections = [
    {
      label: (
        <LinkList
          testID={incidentPageDCID.productList}
          style={{ paddingTop: '11px', color: 'black', paddingLeft: '16px', fontWeight: 500, fill: 'solid #1A2026' }}
        >
          <LinkList.Item
            text={brandDisplayName}
            isInteractive={false}
            // @ts-expect-error FIXME: carina typing
            testID={`${incidentPageDCID.brandList}-${brandDisplayName}`}
          />
        </LinkList>
      ),
      description: '',
      expandedByDefault: true,
      content
    }
  ];
  return sections;
}

export interface IIncidentProps {
  incidentObj: Incident;
  incidentStatus?: string;
}

export function IncidentPage({ incidentObj, incidentStatus = '' }: IIncidentProps): JSX.Element {
  const {
    incidentLabels,
    parentTileStyle,
    productsImpactedTileStyle,
    incidentValueLabels,
    incidentProductsSummaryCol
  } = useStyle(usePageStyles);

  const incidentBrands = useRef<FilteredBrand[]>();
  const incidentDatacenters = useRef<string>();
  const incidentDatacentersubsystemIds = incidentObj.incidentSubsystems.map(
    incidentSubsystem => incidentSubsystem.datacenterSubsystemId
  );
  const getIncidentBrandsProductSubsystemsVariables: GetIncidentBrandsProductsSubsystemsQueryVariables = {
    datacenterSubsystemIds: incidentDatacentersubsystemIds
  };
  const { data, loading } = useGetIncidentBrandsProductsSubsystemsQuery({
    variables: getIncidentBrandsProductSubsystemsVariables
  });
  if (loading) {
    return <LoadingPage />;
  }
  if (data?.getIncidentBrandsProductsSubsystems?.filteredBrands) {
    incidentBrands.current = data?.getIncidentBrandsProductsSubsystems?.filteredBrands;

    const uniqueBrands = getUniqueBrands(incidentBrands.current);
    incidentDatacenters.current = getIncidentDatacenters(incidentBrands.current);
    const incidentSeverity = getIncidentSeverity(incidentObj.incidentSubsystems);
    return (
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Form element="div">
          <Row justifyContent="center">
            <Col {...incidentProductsSummaryCol}>
              <Row>
                <CommonContentContainer width={parentTileStyle.width} styling={{ ...parentTileStyle }}>
                  <Form.Section heading="Incident Information" stack>
                    <div css={incidentLabels}>
                      <Row margin={{ top: -8 }}>
                        <Col l={{ width: 1 / 4 }} flex={{ display: 'flex' }} css={incidentLabels}>
                          <FormElement.Label
                            labelFor={incidentObj.jiraId}
                            label={<IncidentLabel styling={incidentLabels} text="Incident Id" />}
                            testID={incidentPageDCID.incidentIdLabel}
                          />
                        </Col>
                        <Col s={{ width: 1 / 2 }} flex={{ display: 'flex' }} id={incidentObj.jiraId}>
                          <FormElement.Label
                            labelFor={getIncidentIdForDisplay(incidentObj.jiraId)}
                            label={getIncidentIdForDisplay(incidentObj.jiraId)}
                            testID={incidentPageDCID.incidentIdValue}
                          />
                        </Col>
                      </Row>
                      <Row margin={{ top: 20 }}>
                        <Col l={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                          <FormElement.Label
                            labelFor={incidentPageDCID.incidentTitle}
                            label={<IncidentLabel styling={incidentLabels} text="Title" />}
                            testID={incidentPageDCID.incidentTitleLabel}
                          />
                        </Col>
                        <Col l={{ width: 1 / 2 }} flex={{ display: 'flex' }} id={incidentPageDCID.incidentTitle}>
                          <FormElement.Label
                            labelFor={incidentObj.name}
                            label={<IncidentLabel styling={incidentValueLabels} text={incidentObj.name} />}
                            testID={incidentPageDCID.incidentTitle}
                          />
                        </Col>
                      </Row>
                      <Row margin={{ top: 20 }}>
                        <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                          <FormElement.Label
                            labelFor={incidentPageDCID.statusValue}
                            label={<IncidentLabel styling={incidentLabels} text="Incident Status" />}
                            testID={incidentPageDCID.statusLabel}
                          />
                        </Col>
                        <Col
                          s={{ width: 1 / 4 }}
                          flex={{ display: 'flex' }}
                          testID={incidentPageDCID.statusValue}
                          id={incidentPageDCID.statusValue}
                        >
                          <FormElement.Label
                            labelFor={incidentStatus}
                            label={<IncidentLabel styling={incidentValueLabels} text={incidentStatus} />}
                          />
                        </Col>
                      </Row>
                      <Row margin={{ top: 20 }}>
                        <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                          <FormElement.Label
                            labelFor={incidentPageDCID.impactCategoryValue}
                            label={<IncidentLabel styling={incidentLabels} text="Incident Type" />}
                            testID={incidentPageDCID.impactCategoryLabel}
                          />
                        </Col>
                        <Col s={{ width: 1 / 4 }} flex={{ display: 'flex' }} id={incidentPageDCID.impactCategoryValue}>
                          <FormElement.Label
                            // to do:fix this
                            labelFor={incidentSeverity}
                            label={
                              <IncidentLabel styling={incidentValueLabels} text={getStatusName(incidentSeverity)} />
                            }
                            testID={incidentPageDCID.impactCategoryValue}
                          />
                        </Col>
                      </Row>
                      <Row margin={{ top: 20 }}>
                        <Col l={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                          <div style={{ fontWeight: 'bold' }}>
                            <FormElement.Label
                              labelFor={incidentPageDCID.datacenterValue}
                              label={<IncidentLabel styling={incidentLabels} text="Datacenter(s)" />}
                              testID={incidentPageDCID.datacenterLabel}
                            />
                          </div>
                        </Col>
                        {/* to do: fix this */}
                        <Col l={{ width: 3 / 4 }} flex={{ display: 'flex' }} id={incidentPageDCID.datacenterValue}>
                          <FormElement.Label
                            labelFor=""
                            label={<IncidentLabel styling={incidentValueLabels} text={incidentDatacenters.current} />}
                            testID={incidentPageDCID.datacenterValue}
                          />
                        </Col>
                      </Row>
                      <Row margin={{ top: 20 }}>
                        <Col l={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                          <FormElement.Label
                            labelFor="incidentstartDate"
                            label={<IncidentLabel styling={incidentLabels} text="Incident Began" />}
                            testID={incidentPageDCID.startDateLabel}
                          />
                        </Col>
                        <Col l={{ width: 3 / 4 }} flex={{ display: 'flex' }}>
                          <FormElement.Label
                            labelFor={incidentObj.startDate}
                            label={getLocalDateElement(incidentObj.startDate, incidentValueLabels)}
                            testID={incidentPageDCID.startDateValue}
                          />
                        </Col>
                      </Row>
                      {incidentObj.endDate && incidentObj.endDate !== POSTGRES_DEFAULT_INCIDENT_END_DATE && (
                        <Row margin={{ top: 20 }}>
                          <Col l={{ width: 1 / 4 }} flex={{ display: 'flex' }}>
                            <FormElement.Label
                              labelFor={incidentPageDCID.endDateValue}
                              label={<IncidentLabel styling={incidentLabels} text="Incident Resolved" />}
                              testID={incidentPageDCID.endDateLabel}
                            />
                            {/* <b>Incident End Date:</b> */}
                          </Col>
                          <Col s={{ width: 3 / 4 }} flex={{ display: 'flex' }} id={incidentPageDCID.endDateValue}>
                            <FormElement.Label
                              labelFor={incidentObj.endDate}
                              label={getLocalDateElement(incidentObj.endDate, incidentValueLabels)}
                              testID={incidentPageDCID.endDateValue}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Form.Section>
                </CommonContentContainer>
                <IncidentNotes
                  customerImpact={incidentObj.customerImpact}
                  currentUpdate={incidentObj.currentUpdate}
                  incidentHistory={incidentObj.pastUpdates}
                  lastModifiedDate={incidentObj.lastModified}
                  incidentStartDate={incidentObj.startDate}
                  incidentEndDate={incidentObj.endDate}
                />
              </Row>
            </Col>
            <Col {...incidentProductsSummaryCol}>
              <Row>
                <CommonContentContainer
                  width={productsImpactedTileStyle.width}
                  styling={{ ...productsImpactedTileStyle }}
                >
                  <h3 style={{ paddingLeft: '24px' }}>Products Impacted</h3>

                  {uniqueBrands.map(brand => {
                    const uniqueProducts = Array.from(new Map(brand.products.map(p => [p.id, p])).values());
                    return (
                      <Row key={brand.id}>
                        <Col width="fill" padding={{ start: 0, end: 0 }}>
                          {/* to do: fix this */}
                          <Accordion
                            sections={getAccordionSections(
                              brand.name,
                              uniqueProducts,
                              incidentObj.incidentSubsystems,
                              incidentSeverity
                            )}
                            testID={incidentPageDCID.brandList}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </CommonContentContainer>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
  return null;
}

import React, { useRef } from 'react';
import { useGetIncidentByIncidentIdQuery, GetIncidentByIncidentIdQueryVariables, Incident } from '@typings/graph';
import { LoadingSpinner } from '@cvent/carina/components/LoadingSpinner';
import { POSTGRES_DEFAULT_INCIDENT_END_DATE } from '@constants';
import { IncidentPage } from './incidentPage/incident';
import { IncidentPagePanel } from './incidentPanel/incidentPanel';

export interface IIncidentProps {
  incidentId: string;
  panelView: boolean;
}

export function IncidentComponent({ incidentId, panelView }: IIncidentProps): JSX.Element {
  const getIncidentVariables: GetIncidentByIncidentIdQueryVariables = {
    incidentId
  };
  const incident = useRef<Incident>();
  const { data, loading } = useGetIncidentByIncidentIdQuery({
    variables: getIncidentVariables
  });
  if (loading) {
    return <LoadingSpinner />;
  }
  if (data?.getIncidentByIncidentId?.incident) {
    incident.current = data.getIncidentByIncidentId?.incident || {};
    const incidentStatus =
      incident.current.endDate && incident.current.endDate !== POSTGRES_DEFAULT_INCIDENT_END_DATE
        ? 'Resolved'
        : 'Ongoing';
    if (panelView) {
      return (
        <div>{panelView && <IncidentPagePanel incidentObj={incident.current} incidentStatus={incidentStatus} />}</div>
      );
    }
    return <IncidentPage incidentObj={incident.current} incidentStatus={incidentStatus} />;
  }
  return null;
}
